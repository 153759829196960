<template>
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <h1 class="display-1 font-weight-bold">
            {{ daysBeforePasswordExpire }}
          </h1>
        </div>
        <div class="mb-2">
          <h2>Days until your password expires</h2>
        </div>
      </div>
      <b-button
        class="py-1"
        type="submit"
        variant="primary"
        block
        :to="{ name: 'security-password-change' }"
      >
        Change Password
      </b-button>
      <div class="text-center pt-1">
        <span>
          <b-link @click="navigateToHome">
            <span class="text-success font-weight-bold cursor-pointer">
              Skip
            </span>
          </b-link>
        </span>
      </div>
    </template>
  </AuthWrapper>
</template>

<script>
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import { BButton, BLink } from "bootstrap-vue";

export default {
  components: {
    AuthWrapper,
    BLink,
    BButton,
  },
  computed: {
    daysBeforePasswordExpire() {
      return this.getValueFromSource(this.currentUser, "days_before_password_expire", 90)
    }
  },
  methods: {
    async navigateToHome() {
      let home_route = "client-home";
      if (this.isCurrentUserOmcAdmin) {
        home_route = "omc-home"
      } else if (this.isCurrentUserPartnerAdmin) {
        home_route = "partner-home"
      }
      await this.$router.push({ name: home_route }).catch(() => {});
    }
  }
};
</script>
